@use '@angular/material' as mat;
@use 'sass:map';

@include mat.core();

$usc-default-typography: mat.define-typography-config(
  $font-family: var(--usc-font-family),
);

//@include mat.typography-hierarchy($usc-default-typography); // todo set type for all mat components
@include mat.table-typography($usc-default-typography);
@include mat.chips-typography($usc-default-typography);

@import '../../node_modules/@uscapp/ngx-style-library/styles/styles'; // also contains library's variables.scss
@import 'swal-styles';

:root {
  --max-page-width: 1248px;
  --cardinal-70: #630000; // todo add to library. also add this into the palette format
  --error-color: #924E4F;
}

@media screen and (max-width: $sm-screen-max) {
  .page-content-wrapper {
    padding: 0 16px;
  }
}

@media screen and (min-width: $lg-screen-min) {
  .page-content-wrapper {
    padding: 0 32px;
  }
}

@media screen and (min-width: $sm-screen-max) {
  .page-background {
    background-color: #FFFFFF;
    border: 1px solid #E0E0DC;
    border-radius: 4px;
  }
}

html, body {
  height: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: var(--usc-font-family);
  font-size: var(--primary-font-size);
  background-color: var(--primary-background-color);
}

body {
  display: flex;
  flex-direction: column;
}

.page-content-wrapper {
  max-width: var(--max-page-width);
  margin: 0 auto;
}

/**
 * Remove focus styles for non-keyboard focus
 */
:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}


@mixin color($my-theme) {
  $color-config: mat.get-color-config($my-theme);

  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');

  .mat-button-toggle {
    &.custom {
    }

    &.transparent {
      background-color: #E0E0DC;
    }
  }

  .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
    &.custom {
      background-color: mat.get-color-from-palette($primary-palette, 500);
      color: #ffffff;
    }

    &.custom-white {
      background-color: white;
    }

    &.transparent, &.custom-white {
      font-size: 16px;
      font-weight: 900;
      border-bottom: solid 4px var(--cardinal-50);
    }
  }

  .school-accordion-tab-group {
    mat-tab-group {
      mat-tab-header {
        padding-left: 8px;
        padding-right: 8px;

        .mat-mdc-tab-label-container {
          .mat-mdc-tab {
            padding-left: 11px;
            padding-right: 11px;
          }

          .mdc-tab--active {
            font-weight: 900;
          }

          .mdc-tab__content {
            .mdc-tab__text-label {
              color: var(--primary-font-color);
              font-size: var(--primary-font-size);
              letter-spacing: 0;
            }
          }

          .mdc-tab-indicator {
            .mdc-tab-indicator__content {
              border-width: 4px;
            }
          }
        }
      }
    }


    .mat-mdc-tab-nav-bar {
      padding-left: 8px;
      padding-right: 8px;

      .mat-mdc-tab-link-container {
        .mat-mdc-tab-links {
          .mat-mdc-tab-link {
            padding-left: 11px;
            padding-right: 11px;

            .mdc-tab__content {
              .mdc-tab__text-label {
                color: var(--primary-font-color);
                font-size: var(--primary-font-size);
                letter-spacing: 0;
              }
            }
          }

          .mdc-tab--active {
            font-weight: 900;
          }

          .mdc-tab-indicator {
            .mdc-tab-indicator__content {
              border-width: 4px;
            }
          }
        }
      }
    }
  }
}

@include color($my-theme);


.mat-expansion-panel-body {
  padding: 0 !important;
}


//todo - remove margin (& possibly other) styles from heading tags so we can utilize the aria advantages


.search-results-course-panel {
  .course-expansion-panel .mat-expansion-panel-header {
    min-height: 66px;
  }

  .mat-expansion-panel-content .mat-expansion-panel-body {
    padding-left: 16px !important;
  }
}

.overflow-hidden {
  overflow: hidden;
}


.z-index-10k {
  z-index: 10000;
  position: relative;
}

// todo consider mat-overrides stylesheet

.usc-datepicker-container {
  .usc-datepicker-input-container {
    display: flex;
    flex-direction: column;

    .usc-datepicker-form-field {
      width: 240px;
      height: 32px;
      border: 1px solid #C3C2BB;

      &.error {
        border: 1px solid var(--error-color) !important;
      }

      .mat-mdc-text-field-wrapper {
        height: 32px;
      }

      .mat-mdc-form-field-flex {
        .mdc-notched-outline {
          display: none;
        }

        .mat-mdc-form-field-infix {
          height: 32px;
          padding-top: 4px;
          padding-bottom: 12px;
          min-height: 32px;
        }
      }

      .mat-mdc-form-field-icon-suffix {
        .mat-datepicker-toggle {
          button.mdc-icon-button {
            height: 32px;
            width: 32px;
            padding: 5px;
          }
        }
      }
    }
  }
}

.usc-form-field-explanation {
  font-size: 12px;
  font-family: var(--usc-font-family);
  color: var(--primary-font-color);
  letter-spacing: 0;
  line-height: 16px;
}

button.cardinal-button {
  font-size: var(--primary-font-size);
  letter-spacing: normal;
  height: 40px !important;

  &.outline {
    border-color: var(--cardinal-50);

    &:hover {
      color: #fff;
      background-color: var(--cardinal-60);
    }
  }

  &.fill {
    color: #fff;
    background-color: var(--cardinal-50);

    &[disabled] {
      background-color: #DAA7A7;
    }

    &:hover {
      background-color: var(--cardinal-60);
    }
  }
}

input.mdc-text-field__input {
  caret-color: var(--primary-font-color) !important; // todo this should be set in theme colors
}

.mdc-menu-surface.mat-mdc-autocomplete-panel { // list-items in autocomplete
  padding: 4px 0 !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(239, 239, 239, 0.3) !important;
}

.text-bold {
  font-weight: bold;
}

mat-chip-set {
  margin: 0;

  .mat-mdc-chip {
    letter-spacing: 0;
    line-height: 16px;
    height: 20px;

    &.mat-chip-wrapper-red {
      background-color: #ffbdc4;
    }

    &.mat-chip-wrapper-yellow {
      background-color: #ffefba;
    }

    .mat-chip-icon {
      font-size: 12px;
      height: auto;

      &.mat-chip-red {
        color: #e70f0f !important;
      }

      &.mat-chip-yellow {
        color: #ffb000 !important;
      }
    }
  }
}

nav {
  a.header-nav-link {
    font-size: 16px;
    font-family: var(--usc-font-family);
    font-weight: bold;
    letter-spacing: 0.1px;
    line-height: 20px;
    padding: 0 24px 0 24px;

    border-left: 1px solid var(--cardinal-60);

    &:last-child {
      border-right: 1px solid var(--cardinal-60);
    }

    height: 100% !important;
  }
}

.launch-icon {
  font-size: 18px;
  vertical-align: sub;
  align-content: end;
}

.custom-dialog-backdrop {
  background-color: rgba(0, 0, 0, 0.3);
}

.loading-spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 64px auto
}

// material table paginator
.paginator {
  margin-top: 15px;
  font-size: 14px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  color: #44433C;

  .mdc-notched-outline > * {
    border: none;
  }

  .mat-mdc-select-value-text {
    font-size: 16px;
  }
}

.mat-mdc-paginator-container {
  min-height: 48px;
  padding: 0 !important;
  font-size: 16px;
  letter-spacing: 0.06px;

  .mat-mdc-paginator-range-actions {
    border-left: 1px solid #EDEDEB;
    border-radius: 0;
    min-height: 48px;
    min-width: 48px;
    margin: 0;
    padding: 0;

    .mat-mdc-icon-button {
      border-left: 1px solid #EDEDEB;
      border-radius: 0;
      min-height: 48px;
      min-width: 48px;
      margin: 0;
      padding: 0;
    }
  }
}
