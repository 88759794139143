body.swal2-no-backdrop .swal2-container .swal2-modal.swal-custom-container {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 0;
}

.swal-custom-container {
  border-left: 8px solid;
  top: 31%;

  &.error {
    border-left-color: #E0182D;
  }

  &.success {
    border-left-color: #42A517;
  }

  &.info {
    border-left-color: #86C1FC;
  }

  &.warning {
    border-left-color: #FFB000;
  }

  #swal2-html-container {
    margin: 0;
    padding: 20px 16px 22px 0;
  }

  .swal-custom-close-button {
    font-size: 25px;
    color: #2B2A23;
  }

  .alert-container {
    display: flex;

    .icon-container {
      display: flex;
      align-items: center;
      margin-right: 18px;
      margin-left: 10px;
    }

    .contents {
      text-align: initial;

      color: #2B2A23;
      font-family: var(--usc-font-family);
      font-size: 16px;
      letter-spacing: 0;
      line-height: 20px;

      .title {
        font-weight: bold;
      }

      .message {

      }
    }
  }
}
